import AlephBet from "alephbet"
import {storage_adapter, super_properties_lamed_adapter} from "./adapters"
import {
  on_pricing_page,
  get_user_id,
  logged_in_potential_customer,
  on_en_page,
  on_es_page,
  on_de_page
} from "./helpers"
import {lifetime_checkout_page_visited, checkout_page_visited} from "./goals"
import $ from "cash-dom"

export default () => {
  const show_muscle_charts_as_lifetime_only_feature = new AlephBet.Experiment({
    name: "202504 Show muscle charts as Lifetime-only feature",
    tracking_adapter: super_properties_lamed_adapter,
    user_id: get_user_id(),
    storage_adapter: storage_adapter,
    trigger: () =>
      logged_in_potential_customer() &&
      on_pricing_page() &&
      (
        on_en_page() ||
        on_es_page() ||
        on_de_page()
      ),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Show-muscle-charts": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return

          $("body").addClass("ab-muscle-charts-as-lifetime-only-feature")
        }
      }
    }
  })

  show_muscle_charts_as_lifetime_only_feature.add_goals([
    lifetime_checkout_page_visited,
    checkout_page_visited
  ])
}
